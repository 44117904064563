import { Exchange, ExchangeEnum } from "../enums/Exchange";
import { MarketType } from "../enums/MarketType";

export const getExchangeUri = (exchange:Exchange|undefined, market:MarketType, base:string, quote:string, ) => {

    if (typeof exchange === 'undefined') {
        return "";
    }

    let uri = "";

    if ((exchange.toString() === Exchange.Huobi.toString() || exchange.toString() === ExchangeEnum.Huobi.toString()) && market === MarketType.Spot) {
        uri = `https://www.huobi.com/en-us/exchange/${base.toLowerCase()}_${quote.toLowerCase()}`;
    }
    else if ((exchange.toString() === Exchange.Huobi.toString() || exchange.toString() === ExchangeEnum.Huobi.toString()) && market === MarketType.Futures) {
        uri = `https://futures.huobi.com/en-us/linear_swap/exchange/#contract_code=${base}-${quote.toLowerCase()}&contract_type=swap`;
    }
    else if ((exchange.toString() === Exchange.MEXC.toString() || exchange.toString() === ExchangeEnum.MEXC.toString()) && market === MarketType.Spot) {
        uri = `https://www.mexc.com/exchange/${base}_${quote}`;
    }
    else if ((exchange.toString() === Exchange.MEXC.toString() || exchange.toString() === ExchangeEnum.MEXC.toString()) && market === MarketType.Futures) {
        uri = `https://futures.mexc.com/exchange/${base}_${quote}`;
    }
    else if ((exchange.toString() === Exchange.GateIo.toString() || exchange.toString() === ExchangeEnum.GateIo.toString()) && market === MarketType.Spot) {
        uri = `https://www.gate.io/trade/${base}_${quote}`;
    }
    else if ((exchange.toString() === Exchange.GateIo.toString() || exchange.toString() === ExchangeEnum.GateIo.toString()) && market === MarketType.Futures) {
        uri = `https://www.gate.io/futures_trade/${base}_${quote}`;
    }
    else if ((exchange.toString() === Exchange.Bitget.toString() || exchange.toString() === ExchangeEnum.Bitget.toString()) && market === MarketType.Spot) {
        uri = `https://www.bitget.com/spot/${base}${quote}`;
    }
    else if ((exchange.toString() === Exchange.Bitget.toString() || exchange.toString() === ExchangeEnum.Bitget.toString()) && market === MarketType.Futures) {
        uri = `https://www.bitget.com/futures/${quote.toLowerCase()}/${base}${quote}`;
    }
    else if ((exchange.toString() === Exchange.ByBit.toString() || exchange.toString() === ExchangeEnum.ByBit.toString()) && market === MarketType.Spot) {
        uri = `https://www.bybit.com/en/trade/spot/${base}/${quote}`;
    }
    else if ((exchange.toString() === Exchange.ByBit.toString() || exchange.toString() === ExchangeEnum.ByBit.toString()) && market === MarketType.Futures) {
        uri = `https://www.bybit.com/en/trade/${quote.toLowerCase()}/${base}/${quote}`;
    }
    else if ((exchange.toString() === Exchange.Binance.toString() || exchange.toString() === ExchangeEnum.Binance.toString()) && market === MarketType.Spot) {
        uri = `https://www.binance.com/en/trade/${base}_${quote}?type=spot`;
    }
    else if ((exchange.toString() === Exchange.Binance.toString() || exchange.toString() === ExchangeEnum.Binance.toString()) && market === MarketType.Futures) {
        uri = `https://www.binance.com/en/futures/${base}${quote}`;
    }
    else if ((exchange.toString() === Exchange.KuCoin.toString() || exchange.toString() === ExchangeEnum.KuCoin.toString()) && market === MarketType.Futures) {
        uri = `https://futures.kucoin.com/trade/${base}${quote}M`;
    }
    else if ((exchange.toString() === Exchange.KuCoin.toString() || exchange.toString() === ExchangeEnum.KuCoin.toString()) && market === MarketType.Spot) {
        uri = `https://www.kucoin.com/trade/${base}-${quote}`;
    }
    else if (exchange.toString() === Exchange.CoinBase.toString() || exchange.toString() === ExchangeEnum.CoinBase.toString()) {
        uri = `https://www.coinbase.com/advanced-trade/${base}-${quote}`
    }
    else if ((exchange.toString() === Exchange.OKX.toString() || exchange.toString() === ExchangeEnum.OKX.toString()) && market === MarketType.Spot) {
        uri = `https://www.okx.com/trade-spot/${base.toLowerCase()}-${quote.toLowerCase()}`;
    }
    else if ((exchange.toString() === Exchange.OKX.toString() || exchange.toString() === ExchangeEnum.OKX.toString()) && market === MarketType.Futures) {
        uri = `https://www.okx.com/trade-swap/${base.toLowerCase()}-${quote.toLowerCase()}-swap`;
    }
    else if ((exchange.toString() === Exchange.XT.toString() || exchange.toString() === ExchangeEnum.XT.toString()) && market === MarketType.Spot) {
        uri = `https://www.xt.com/en/trade/${base.toLowerCase()}_${quote.toLowerCase()}/`;
    }

    return uri;
}

export function castStringToEnum<T extends Record<string, string>>(
    value: string,
    enumObj: T
  ): T[keyof T] | undefined {
    const enumValues = Object.values(enumObj);
    if (enumValues.includes(value)) {
      return value as T[keyof T];
    }
    return undefined;
  }